import React from "react";

import "./list-page.scss";

import recipes from "../../assets/recipes.json";
// import tags from "../../assets/tags.json";

class ListPage extends React.Component {
  constructor(props) {
    super(props);

    const combinedTags = recipes.recipes.flatMap((recipe) => recipe.tags);
    const allTags = [...new Set(combinedTags)];
    const filters = allTags.map((tag) => {
      return {
        name: tag,
        enabled: true,
      };
    });

    this.state = {
      filters,
    };
  }

  handleFilter = (event) => {
    const id = event.target.id;
    const checked = event.target.checked;
    const filters = this.state.filters.map(({ name, enabled }) => {
      return name === id ? { name, enabled: checked } : { name, enabled };
    });
    this.setState({ ...this.state, filters });
  };

  render() {
    const disabledTags = this.state.filters
      .filter((filter) => !filter.enabled)
      .flatMap((filter) => filter.name);
    const filteredRecipes = recipes.recipes.filter(
      (recipe) => !disabledTags.some((tag) => recipe.tags.includes(tag))
    );
    console.log(`TAGS: ${disabledTags}`);
    console.log(`FILTERED ${JSON.stringify(filteredRecipes)}`);

    return (
      <div className="container">
        <div className="header">
          <div className="header-icon">
            <h2>Food Picker</h2>
          </div>
        </div>
        <div className="content">
          <div className="result-header">
            <div className="result-count">{filteredRecipes.length} results</div>
          </div>
          <div className="grid-container">
            <aside className="filter-pane">
              <form>
                {this.state.filters.map(({ name, enabled }) => (
                  <div className="tag-filter" key={`${name}Tag`}>
                    <input
                      type="checkbox"
                      name={name}
                      id={name}
                      onChange={this.handleFilter}
                      checked={enabled}
                    />
                    <label htmlFor={name}>{name}</label>
                  </div>
                ))}
              </form>
            </aside>

            <div className="results">
              {filteredRecipes.map((recipe) => (
                <div className="recipe">
                  <div className="recipe-name">{recipe.name}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ListPage;
